@keyframes candyCrushPopup {
    0% {
      transform: scale(0.5) translateY(50%);
      opacity: 0;
    }
    50% {
      transform: scale(1.1) translateY(-10%);
      opacity: 1;
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
  
  .candy-toast {
    animation: candyCrushPopup 0.6s ease-in-out;
  }
  